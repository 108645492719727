<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-05 20:39:21
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-10 10:12:30
-->
<template>
  <div>
    <modal
      width="900px"
      :visible="visible"
      title="订单预约时间"
      @close="handleClose"
    >
      <div class="modal-body">
        <el-form class="modal-form" ref="ruleForm" :model="form" :rules="rules">
          <el-form-item label="预约日期" prop="date">
            <el-date-picker
              :picker-options="pickerOptions"
              :append-to-body="false"
              v-model="form.date"
              type="date"
              placeholder="选择日期"
              width="100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="预约时间" prop="time">
            <el-time-picker
              :append-to-body="false"
              is-range
              :clearable="false"
              v-model="form.time"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              format="HH:mm"
            >
            </el-time-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="onSubmit">
          确 定
        </el-button>
      </span>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import moment from "moment";
export default {
  components: {
    Modal
  },
  data() {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      visible: false,
      form: {
        date: new Date(),
        time: [
          new Date(),
          new Date(new Date().setHours(new Date().getHours() + 1))
        ]
      },
      orderId: null,
      rules: {
        date: [
          {
            required: true,
            message: "请选择预约日期",
            trigger: "change"
          }
        ],
        time: [
          {
            required: true,
            message: "请选择预约时间",
            trigger: "change"
          }
        ]
      }
    };
  },
  mounted() {},
  methods: {
    init(id) {
      this.orderId = id;
      this.visible = true;
      this.form = {
        date: new Date(),
        time: [
          new Date(),
          new Date(new Date().setHours(new Date().getHours() + 1))
        ]
      };
    },
    handleClose() {},
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let params = {
            id: this.orderId,
            date: moment(this.form.date).format("YYYY-MM-DD"),
            timeE: moment(this.form.time[1]).format("HH:mm"),
            timeS: moment(this.form.time[0]).format("HH:mm")
          };
          const loading = this.$loading({
            lock: true,
            text: "预约中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          this.$api
            .installOrderAppointment(params)
            .then(() => {
              this.$message({
                message: "恭喜您，预约成功！",
                type: "success"
              });
              this.orderId = null;
              this.visible = false;
              this.$parent.initInstallationOrder();
            })
            .catch(err => {
              this.$message.error(err?.msg);
            })
            .finally(() => {
              loading.close();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped></style>
